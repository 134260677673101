// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import { Card, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import dataInscripciones from "layouts/autogestion-docente/data/dataInscripciones";
import ModalVerificarExamen from "../ModalVerificarExamen";
import ModalRevision from "../ModalRevision";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import SoftBadge from "components/SoftBadge";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
};

export default function ModalExamen({ open, handleClose, refetch, selectedExamen }) {
  const [rows, setRows] = useState([]);
  const { columns, getRows } = dataInscripciones;
  const [selectedInscripcion, setSelectedInscripcion] = useState(null);
  const [openModalVerificacion, setOpenModalVerificacion] = useState(false);
  const [openModalVerExamen, setOpenModalVerExamen] = useState(false);
  const [inscriptos, setInscriptos] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState(null);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [cerrarActasExamen, { loading: loadingCerrarActas }] = useMutation(
    gql`
      mutation cerrarActasExamen($idExamen: ID!) {
        cerrarActasExamen(idExamen: $idExamen) {
          id
          estado
          nota
        }
      }
    `
  );

  const [finalizarExamen, { loading: loadingFinalizar }] = useMutation(
    gql`
      mutation finalizarExamen($idInscripcion: ID!) {
        finalizarExamen(idInscripcion: $idInscripcion)
      }
    `
  );
  const { handleSnackbar } = useContext(MessageManager);
  const { confirmar } = useContext(ConfirmarContext);

  const [habilitarExamen, { loading: loadingVerificar }] = useMutation(gql`
    mutation habilitarExamen($idInscripcion: ID!) {
      habilitarExamen(idInscripcion: $idInscripcion)
    }
  `);

  const handleHabilitarExamen = (inscripcion) => {
    setSelectedInscripcion(inscripcion);
    confirmar({
      title: "Habilitar exámen",
      message: `¿Está seguro que desea habilitar el exámen para el alumno ${inscripcion.alumno.nombre} ${inscripcion.alumno.apellido}? Una vez habilitado, el alumno podrá realizar el exámen desde su autogestión.`,
      icon: "done_all",
      func: () => {
        habilitarExamen({
          variables: {
            idInscripcion: inscripcion.id,
          },
        })
          .then((res) => {
            if (res.data.habilitarExamen) {
              handleSnackbar("Alumno habilitado para el examen correctamente", "success");
              getInscriptos({ variables: { id: selectedExamen.id } }).then((res) => {
                setInscriptos(res.data.examen.inscripciones);
                setPagesInfo({
                  total: res.data.examen.inscripciones.length,
                  pages: Math.ceil(res.data.examen.inscripciones.length / 10),
                });
              });
            }
          })
          .catch((err) => {
            handleSnackbar(err.message || "Ha ocurrido un error al habilitar el examen", "error");
          });
      },
    });
    // setOpenModalVerificacion(true);
  };

  const handleVerExamen = (inscripcion) => {
    setSelectedInscripcion(inscripcion);
    setOpenModalVerExamen(true);
  };

  const handleFinalizarExamen = (inscripcion) => {
    setSelectedInscripcion(inscripcion);
    confirmar({
      title: "Finalizar exámen",
      message: `¿Está seguro que desea finalizar el exámen? El alumno no podrá continuar con el mismo. Esta acción no se puede deshacer.`,
      icon: "done_all",
      func: () => {
        finalizarExamen({ variables: { idInscripcion: inscripcion.id } })
          .then(() => {
            getInscriptos({ variables: { id: selectedExamen.id } }).then((res) => {
              setInscriptos(res.data.examen.inscripciones);
              setPagesInfo({
                total: res.data.examen.inscripciones.length,
                pages: Math.ceil(res.data.examen.inscripciones.length / 10),
              });
            });
            setSelectedInscripcion(null);
            handleSnackbar("Examen finalizado correctamente", "success");
          })
          .catch((error) => {
            handleSnackbar(error.message || "Ha ocurrido un error al finalizar el examen", "error");
          });
      },
    });
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [getInscriptos, { data, loading }] = useLazyQuery(
    gql`
      query getExamen($id: ID!) {
        examen(id: $id) {
          id
          estado
          inscripciones {
            id
            estado
            nota
            requiereRevision
            examen {
              id
              fecha
            }
            alumno {
              id
              nombre
              apellido
              dni
            }
            acta {
              id
              estado
              nota
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  console.log("data", data);

  useEffect(() => {
    if (open && selectedExamen) {
      getInscriptos({ variables: { id: selectedExamen.id } }).then((res) => {
        setInscriptos(res.data.examen.inscripciones);
        setPagesInfo({
          total: res.data.examen.inscripciones.length,
          pages: Math.ceil(res.data.examen.inscripciones.length / 10),
        });
      });
    }
  }, [open, selectedExamen]);

  useEffect(() => {
    if (inscriptos) {
      let inscriptosFiltered = inscriptos;
      if (search) {
        inscriptosFiltered = inscriptosFiltered.filter((inscripcion) => {
          const nombre = `${inscripcion?.alumno?.apellido} ${inscripcion?.alumno?.nombre}`;
          return nombre.toLowerCase().includes(search.toLowerCase());
        });
      }
      inscriptosFiltered = inscriptosFiltered.slice((page - 1) * 10, page * 10);

      setRows(
        getRows(inscriptosFiltered, handleHabilitarExamen, handleVerExamen, handleFinalizarExamen)
      );
    }
  }, [inscriptos, search, page]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <Card>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                  <SoftBox
                    display={{
                      xs: "flex-row",
                      sm: "flex",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                      <SoftBox display="flex" alignItems="center" gap={1}>
                        <SoftTypography variant="h6">
                          Inscripciones al examen {selectedExamen?.titulo}
                        </SoftTypography>
                        {data?.examen?.estado === "Finalizado" && (
                          <SoftBadge color={"primary"} badgeContent={data?.examen.estado} />
                        )}
                      </SoftBox>

                      <SoftBox
                        display={{
                          xs: "block",
                          sm: "none",
                        }}
                        mr={2}
                      >
                        <Tooltip title="Cerrar actas de exámen" placement="top">
                          <SoftButton
                            color="uproGreen"
                            circular
                            iconOnly
                            disabled={
                              inscriptos.some((inscripcion) => inscripcion.estado === "En curso") ||
                              data?.examen.estado === "Finalizado" ||
                              loading
                            }
                            onClick={() => {
                              confirmar({
                                title: "Cerrar actas de exámen",
                                message: `¿Está seguro que desea cerrar las actas de exámen? Una vez cerradas, no podrá habilitar a los alumnos para rendir el examen. Esta acción no se puede deshacer.`,
                                icon: "done_all",
                                func: () => {
                                  cerrarActasExamen({ variables: { idExamen: selectedExamen.id } })
                                    .then(() => {
                                      handleSnackbar("Actas cerradas correctamente", "success");
                                      refetch();
                                      getInscriptos({ variables: { id: selectedExamen.id } }).then(
                                        (res) => {
                                          setInscriptos(res.data.examen.inscripciones);
                                          setPagesInfo({
                                            total: res.data.examen.inscripciones.length,
                                            pages: Math.ceil(
                                              res.data.examen.inscripciones.length / 10
                                            ),
                                          });
                                        }
                                      );
                                    })
                                    .catch((error) => {
                                      handleSnackbar(
                                        error.message || "Ha ocurrido un error al cerrar las actas",
                                        "error"
                                      );
                                    });
                                },
                              });
                            }}
                          >
                            <Icon>done</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      <SoftBox
                        display={{
                          xs: "block",
                          sm: "none",
                        }}
                      >
                        <Tooltip title="Actualizar tabla" placement="top">
                          <SoftButton
                            color="uproYellow"
                            circular
                            iconOnly
                            onClick={() => {
                              getInscriptos({ variables: { id: selectedExamen.id } }).then(
                                (res) => {
                                  setInscriptos(res.data.examen.inscripciones);
                                  setPagesInfo({
                                    total: res.data.examen.inscripciones.length,
                                    pages: Math.ceil(res.data.examen.inscripciones.length / 10),
                                  });
                                }
                              );
                            }}
                          >
                            <Icon>refresh</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      mt={{
                        xs: 2,
                        sm: 0,
                      }}
                    >
                      <SoftBox
                        mr={{
                          xs: 0,
                          sm: 2,
                        }}
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "auto",
                          },
                        }}
                      >
                        <SoftInput
                          placeholder="Escribe aquí..."
                          icon={{ component: "search", direction: "left" }}
                          onChange={handleSearch}
                        />
                      </SoftBox>
                      <SoftBox
                        display={{
                          xs: "none",
                          sm: "block",
                        }}
                        mr={2}
                      >
                        <Tooltip title="Cerrar actas de exámen" placement="top">
                          <SoftButton
                            color="uproGreen"
                            circular
                            disabled={
                              inscriptos.some((inscripcion) => inscripcion.estado === "En curso") ||
                              data?.examen.estado === "Finalizado" ||
                              loading
                            }
                            onClick={() => {
                              confirmar({
                                title: "Cerrar actas de exámen",
                                message: `¿Está seguro que desea cerrar las actas de exámen? Una vez cerradas, no podrá habilitar a los alumnos para rendir el examen. Esta acción no se puede deshacer.`,
                                icon: "done_all",
                                func: () => {
                                  cerrarActasExamen({ variables: { idExamen: selectedExamen.id } })
                                    .then(() => {
                                      handleSnackbar("Actas cerradas correctamente", "success");
                                      getInscriptos({ variables: { id: selectedExamen.id } }).then(
                                        (res) => {
                                          setInscriptos(res.data.examen.inscripciones);
                                          setPagesInfo({
                                            total: res.data.examen.inscripciones.length,
                                            pages: Math.ceil(
                                              res.data.examen.inscripciones.length / 10
                                            ),
                                          });
                                        }
                                      );
                                    })
                                    .catch((error) => {
                                      handleSnackbar(
                                        error.message || "Ha ocurrido un error al cerrar las actas",
                                        "error"
                                      );
                                    });
                                },
                              });
                            }}
                          >
                            <Icon>done_all</Icon>
                            &nbsp;Cerrar actas
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      <SoftBox
                        display={{
                          xs: "none",
                          sm: "block",
                        }}
                      >
                        <Tooltip title="Actualizar tabla" placement="top">
                          <SoftButton
                            color="uproYellow"
                            circular
                            iconOnly
                            onClick={() => {
                              getInscriptos({ variables: { id: selectedExamen.id } }).then(
                                (res) => {
                                  setInscriptos(res.data.examen.inscripciones);
                                  setPagesInfo({
                                    total: res.data.examen.inscripciones.length,
                                    pages: Math.ceil(res.data.examen.inscripciones.length / 10),
                                  });
                                }
                              );
                            }}
                          >
                            <Icon>refresh</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {loading ? (
                  <Loading />
                ) : rows?.length > 0 ? (
                  <DataTable columns={columns} rows={rows} />
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
              <CustomPagination
                loading={loading}
                length={rows?.length}
                total={pagesInfo?.total}
                pages={pagesInfo?.pages}
                page={page}
                setPage={setPage}
                noCard
              />
            </Card>
          </SoftBox>
          <ModalVerificarExamen
            open={openModalVerificacion}
            handleClose={() => {
              setOpenModalVerificacion(false);
              setSelectedInscripcion(null);
            }}
            inscripcion={selectedInscripcion}
            refetch={() => {
              getInscriptos({ variables: { id: selectedExamen.id } }).then((res) => {
                setInscriptos(res.data.examen.inscripciones);
                setPagesInfo({
                  total: res.data.examen.inscripciones.length,
                  pages: Math.ceil(res.data.examen.inscripciones.length / 10),
                });
              });
            }}
          />
          <ModalRevision
            open={openModalVerExamen}
            handleClose={() => setOpenModalVerExamen(false)}
            idInscripcion={selectedInscripcion?.id}
            refetch={() => {
              getInscriptos({ variables: { id: selectedExamen.id } }).then((res) => {
                setInscriptos(res.data.examen.inscripciones);
                setPagesInfo({
                  total: res.data.examen.inscripciones.length,
                  pages: Math.ceil(res.data.examen.inscripciones.length / 10),
                });
              });
            }}
            readOnly={selectedInscripcion?.examen?.estado === "Finalizado"}
          />
        </Card>
      </Fade>
    </Modal>
  );
}

ModalExamen.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedExamen: PropTypes.object,
};
