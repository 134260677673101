import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import { Card, Divider, Icon, Skeleton, Tooltip } from "@mui/material/";
import Button from "@mui/material/Button";

// Kaizen Dashboard components
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { API_URL } from "services/config";

import { MessageManager } from "context";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";
import { gql, useMutation } from "@apollo/client";

function ProfileCard({ color, usuario, carrera, loading, handleClick }) {
  const [inscripciones, setInscripciones] = useState([]);
  const [token, setToken] = useState(null);
  const [timer, setTimer] = useState(60);
  const [intervalId, setIntervalId] = useState(null);
  const [progress, setProgress] = useState(100);
  const [generateToken, { loading: loadingToken }] = useMutation(gql`
    mutation generateToken {
      generateToken
    }
  `);

  useEffect(() => {
    if (usuario?.inscripciones) {
      setInscripciones(
        usuario.inscripciones
          ?.filter((inscripcion) => inscripcion?.tipo === "Inscripto")
          ?.sort((a, b) => a.id - b.id)
      );
    }
  }, [usuario]);

  const handleGenerateToken = async () => {
    const res = await generateToken();
    setToken(res.data.generateToken?.token);

    if (intervalId) {
      clearInterval(intervalId);
    }

    setProgress(100);
    setTimer(60);

    const newIntervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(newIntervalId);
          setToken(null);
          setProgress(0);
          return 60;
        }
        setProgress((prevTimer - 1) * (100 / 60));
        return prevTimer - 1;
      });
    }, 1000);

    setIntervalId(newIntervalId);
  };

  return (
    <Card>
      <SoftBox p={2} display="flex" justifyContent="center">
        {loading ? (
          <Skeleton>
            <SoftBox
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor={color}
              color="white"
              width="8rem"
              height="8rem"
              shadow="md"
              borderRadius="lg"
              variant="gradient"
            ></SoftBox>
          </Skeleton>
        ) : (
          <SoftBox display="flex" justifyContent="center" alignItems="center" py={2}>
            <SoftAvatar
              display="grid"
              bgColor={color}
              color="white"
              width="8rem"
              height="8rem"
              shadow="md"
              variant="rounded"
              src={usuario?.foto ? `${API_URL}/${usuario.foto}` : null}
              size="xxl"
            />
          </SoftBox>
        )}
      </SoftBox>
      <SoftBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {loading ? (
            <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
            </SoftBox>
          ) : (
            usuario?.nombre + " " + usuario?.apellido
          )}
        </SoftTypography>
        <SoftTypography variant="h7" color="text" fontWeight="regular">
          {loading ? (
            <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Skeleton variant="text" width={100} sx={{ fontSize: "0.5rem" }} />
            </SoftBox>
          ) : (
            usuario?.username
          )}
        </SoftTypography>
        <SoftBox mt={1} display="flex" alignItems="center" justifyContent="center">
          {loading ? (
            <Skeleton variant="text" width={100} sx={{ fontSize: "2rem" }} />
          ) : (
            <SoftBadge
              color={
                inscripciones?.find((inscripcion) => inscripcion?.carrera?.id === carrera?.id)
                  ?.tipo === "Inscripto"
                  ? "uproGreen"
                  : "uproYellow"
              }
              badgeContent={
                inscripciones?.find((inscripcion) => inscripcion?.carrera?.id === carrera?.id)
                  ?.tipo || "Aspirante"
              }
            />
          )}
        </SoftBox>
        <Divider />
        <SoftBox display="flex" alignItems="center" justifyContent="center" py={1}>
          <SoftBox display="flex" alignItems="center" mr={1}>
            <Icon color="primary">description</Icon>
          </SoftBox>
          <SoftTypography variant="h7" color="text" fontWeight="regular">
            {loading ? (
              <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Skeleton variant="text" width={100} sx={{ fontSize: "0.5rem" }} />
              </SoftBox>
            ) : usuario?.nroLegajo ? (
              `Legajo Nº: ${usuario?.nroLegajo}`
            ) : (
              "Sin legajo"
            )}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" alignItems="center" justifyContent="center" py={1}>
          <SoftBox display="flex" alignItems="center" mr={1}>
            <Icon color="primary">badge</Icon>
          </SoftBox>
          <SoftTypography variant="h7" color="text" fontWeight="regular">
            {loading ? (
              <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Skeleton variant="text" width={100} sx={{ fontSize: "0.5rem" }} />
              </SoftBox>
            ) : (
              `DNI: ${usuario?.dni}`
            )}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" alignItems="center" justifyContent="center" py={1}>
          <SoftButton color="uproYellow" fullWidth circular onClick={handleClick}>
            <Icon>edit</Icon>
            &nbsp; Editar Perfil
          </SoftButton>
        </SoftBox>
        <SoftBox display="flex" alignItems="center" justifyContent="center" py={1}>
          <SoftButton
            color="primary"
            fullWidth
            circular
            onClick={handleGenerateToken}
            sx={{
              position: "relative",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: `${progress}%`,
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                transition: "width 1s ease-in-out",
                zIndex: 1,
              },
              "& > *": {
                position: "relative",
                zIndex: 2,
              },
            }}
          >
            <Icon>{loadingToken ? "hourglass_empty" : token ? "refresh" : "vpn_key"}</Icon>
            &nbsp;{loadingToken ? "Generando" : token ? token : "Generar token"}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ProfileCard
ProfileCard.defaultProps = {
  color: "primary",
  usuario: {
    sucursal: {},
    puesto: {},
    user: {},
    localidad: { provincia: {} },
    sucursales: [{}],
  },
  loading: true,
  handleClick: () => {},
};

// Typechecking props for the ProfileCard
ProfileCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "terraPrimary",
    "terraSecondary",
  ]),
  usuario: PropTypes.object.isRequired,
  carrera: PropTypes.object,
  loading: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default ProfileCard;
